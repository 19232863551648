<script>
    export default {
        data() {
            return {
                importStatus: [
                    {
                        value: 'pending',
                        text: this.getI18n('IMPORT_JOBS', 'STATUSES.pending'),
                    },
                    {
                        value: 'failed',
                        text: this.getI18n('IMPORT_JOBS', 'STATUSES.failed'),
                    },
                    {
                        value: 'completed',
                        text: this.getI18n('IMPORT_JOBS', 'STATUSES.completed'),
                    },
                ],
                importTypes: [
                    {
                        value: 'questionnaire_answer',
                        text: this.getI18n('EXPORT_JOBS', 'TYPES.questionnaire_answer'),
                    },
                    {
                        value: 'status_day',
                        text: this.getI18n('EXPORT_JOBS', 'TYPES.status_day'),
                    },
                    {
                        value: 'general',
                        text: this.getI18n('EXPORT_JOBS', 'TYPES.general'),
                    },
                    {
                        value: 'justification',
                        text: this.getI18n('EXPORT_JOBS', 'TYPES.justification'),
                    },
                    {
                        value: 'justification_type',
                        text: this.getI18n('EXPORT_JOBS', 'TYPES.justification_type'),
                    },
                    {
                        value: 'product',
                        text: this.getI18n('PRODUCTS', 'TITLES.product'),
                    },
                    {
                        value: 'mix',
                        text: this.getI18n('MIXES', 'TITLES.mix'),
                    },
                    {
                        value: 'mix_has_product',
                        text: this.getI18n('MIXES', 'TITLES.mix_has_product'),
                    },
                    {
                        value: 'product_category',
                        text: this.getI18n(
                            'PRODUCT_CATEGORIES',
                            'TITLES.product_category'
                        ),
                    },
                    {
                        value: 'users',
                        text: this.getI18n('USERS', 'TITLES.user'),
                    },
                    {
                        value: 'work_schedule',
                        text: this.getI18n('IMPORT_JOBS', 'TYPES.work_schedule'),
                    },
                    {
                        value: 'point_of_sale',
                        text: this.getI18n('POS', 'TITLES.pos'),
                    },
                    {
                        value: 'point_of_sale_channel',
                        text: this.getI18n(
                            'IMPORT_JOBS',
                            'TYPES.point_of_sale_channel'
                        ),
                    },
                    {
                        value: 'point_of_sale_chain',
                        text: this.getI18n(
                            'IMPORT_JOBS',
                            'TYPES.point_of_sale_chain'
                        ),
                    },
                    {
                        value: 'point_of_sale_regional',
                        text: this.getI18n(
                            'IMPORT_JOBS',
                            'TYPES.point_of_sale_regional'
                        ),
                    },
                    {
                        value: 'point_of_sale_flag',
                        text: this.getI18n(
                            'IMPORT_JOBS',
                            'TYPES.point_of_sale_flag'
                        ),
                    },
                    {
                        value: 'point_of_sale_category',
                        text: this.getI18n(
                            'IMPORT_JOBS',
                            'TYPES.point_of_sale_category'
                        ),
                    },
                    {
                        value: 'point_of_sale_revenue_has_business_unit',
                        text: this.getI18n(
                            'IMPORT_JOBS',
                            'TYPES.point_of_sale_revenue_has_business_unit'
                        ),
                    },
                    {
                        value: 'brand',
                        text: this.getI18n('IMPORT_JOBS', 'TYPES.brand'),
                    }
                    ,{
                        value: 'business_unit',
                        text: this.getI18n('IMPORT_JOBS', 'TYPES.business_unit'),
                    },
                    {
                        value: 'point_of_sale_chains_has_flag',
                        text: this.getI18n(
                            'IMPORT_JOBS',
                            'TYPES.point_of_sale_chains_has_flag'
                        ),
                    },
                ],
                importFields: {
                    users: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                        email: {
                            required: false,
                            label: this.getI18n('USERS', 'FIELDS.user_email'),
                        },
                        password: {
                            required: false,
                            label: this.getI18n('USERS', 'FIELDS.password'),
                        },
                        phone: {
                            required: false,
                            label: this.getI18n('COMMON', 'phone'),
                        },
                        birthday: {
                            required: false,
                            label: this.getI18n('COMMON', 'birthday'),
                        },
                        cpf: {
                            required: false,
                            label: this.getI18n('COMMON', 'cpf'),
                        },
                        rg: {
                            required: false,
                            label: 'RG',
                        },
                        language: {
                            required: false,
                            label: this.getI18n('COMMON', 'language'),
                        },
                        status: {
                            required: true,
                            label: this.getI18n('COMMON', 'active'),
                        },
                        zip_code: {
                            required: false,
                            label: this.getI18n('COMMON', 'postal_code'),
                        },
                        address: {
                            required: false,
                            label: this.getI18n('COMMON', 'address'),
                        },
                        district: {
                            required: false,
                            label: this.getI18n('COMMON', 'district'),
                        },
                        complement: {
                            required: false,
                            label: this.getI18n('COMMON', 'complement'),
                        },
                        number: {
                            required: false,
                            label: this.getI18n('COMMON', 'number'),
                        },
                        city: {
                            required: false,
                            label: this.getI18n('COMMON', 'city'),
                        },
                        state: {
                            required: false,
                            label: this.getI18n('COMMON', 'state'),
                        },
                        admission_date: {
                            required: true,
                            label: this.getI18n('COMMON', 'admission_date'),
                        },
                        contract_type: {
                            required: true,
                            label: this.getI18n('COMMON', 'contract_type'),
                        },
                        role: {
                            required: true,
                            label: this.getI18n('SECURITY', 'TITLES.role'),
                        },
                        workday: {
                            required: true,
                            label: this.getI18n('WORKDAYS', 'TITLES.workday'),
                        },
                        timezone: {
                            required: true,
                            label: this.getI18n('COMMON', 'timezone'),
                        },
                        imei: {
                            required: false,
                            label: this.getI18n('COMMON', 'imei'),
                        },
                        imei_active: {
                            required: false,
                            label: this.getI18n('COMMON', 'imei_active'),
                        },
                        promoter_cod: {
                            required: false,
                            label: this.getI18n('COMMON', 'promoter_cod'),
                        },
                        enterprise_cod: {
                            required: false,
                            label: this.getI18n('COMMON', 'enterprise_cod'),
                        },
                    },
                    product: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                        is_competitor: {
                            required: false,
                            label: this.getI18n('PRODUCTS', 'FIELDS.competitor'),
                        },
                        min_price: {
                            required: false,
                            label: this.getI18n('PRODUCTS', 'FIELDS.min_price'),
                        },
                        max_price: {
                            required: false,
                            label: this.getI18n('PRODUCTS', 'FIELDS.max_price'),
                        },
                        reference: {
                            required: true,
                            label: this.getI18n('PRODUCTS', 'FIELDS.reference'),
                        },
                        bar_code: {
                            required: false,
                            label: this.getI18n('PRODUCTS', 'FIELDS.bar_code'),
                        },
                        due_date: {
                            required: false,
                            label: this.getI18n('PRODUCTS', 'FIELDS.due_date'),
                        },
                        is_category_leader: {
                            required: false,
                            label: this.getI18n(
                                'PRODUCTS',
                                'FIELDS.category_leader'
                            ),
                        },
                        active: {
                            required: false,
                            label: this.getI18n('COMMON', 'active'),
                        },
                        product_category_name: {
                            required: false,
                            label: this.getI18n('PRODUCTS', 'FIELDS.category_name'),
                        },
                        brand_name: {
                            required: false,
                            label: this.getI18n('PRODUCTS', 'FIELDS.brand_name'),
                        },
                    },
                    mix: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                        business_unit_name: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.business_unit_name'
                            ),
                        },
                        external_code: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.mix_external_code'
                            ),
                        },
                        type: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.type'
                            ),
                        },
                        type_name: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.type_name'
                            ),
                        },
                    },
                    product_category: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                        parent_category_name: {
                            required: false,
                            label: this.getI18n(
                                'PRODUCT_CATEGORIES',
                                'IMPORT_FIELDS.parent_category_name'
                            ),
                        },
                    },
                    mix_has_product: {
                        mix_external_code: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.mix_external_code'
                            ),
                        },
                        product_reference: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.product_reference'
                            ),
                        },
                    },
                    work_schedule: {
                        user_email: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.user_email'
                            ),
                        },
                        point_of_sale_external_code: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.point_of_sale_external_code'
                            ),
                        },
                        mix_external_code: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.mix_external_code'
                            ),
                        },
                        business_unit_name: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.business_unit_name'
                            ),
                        },
                        start: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.start'
                            ),
                        },
                        end: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.end'
                            ),
                        },
                        monday: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.monday'
                            ),
                        },
                        tuesday: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.tuesday'
                            ),
                        },
                        wednesday: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.wednesday'
                            ),
                        },
                        thursday: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.thursday'
                            ),
                        },
                        friday: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.friday'
                            ),
                        },
                        saturday: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.saturday'
                            ),
                        },
                        sunday: {
                            required: true,
                            label: this.getI18n(
                                'WORKSCHEDULES',
                                'IMPORT_FIELDS.sunday'
                            ),
                        },
                    },
                    point_of_sale: {
                        name: {
                            required: true,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.name'),
                        },
                        address: {
                            required: true,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.address'),
                        },
                        number: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.number'),
                        },
                        district: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.district'),
                        },
                        city: {
                            required: true,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.city'),
                        },
                        state: {
                            required: true,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.state'),
                        },
                        complement: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.complement'),
                        },
                        chain_name: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.chain_name'),
                        },
                        category_name: {
                            required: false,
                            label: this.getI18n(
                                'POS',
                                'IMPORT_FIELDS.category_name'
                            ),
                        },
                        channel_name: {
                            required: false,
                            label: this.getI18n(
                                'POS',
                                'IMPORT_FIELDS.channel_name'
                            ),
                        },
                        regional_name: {
                            required: true,
                            label: this.getI18n(
                                'POS',
                                'IMPORT_FIELDS.regional_name'
                            ),
                        },
                        flag_name: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.flag_name'),
                        },
                        external_code: {
                            required: true,
                            label: this.getI18n(
                                'POS',
                                'IMPORT_FIELDS.external_code'
                            ),
                        },
                        chain_external_code: {
                            required: false,
                            label: this.getI18n(
                                'POS',
                                'IMPORT_FIELDS.chain_external_code'
                            ),
                        },
                        zip_code: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.zip_code'),
                        },
                        cnpj: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.cnpj'),
                        },
                        number_checkout: {
                            required: false,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.number_checkout'),
                        },
                        commecial_responsible_name: {
                            required: false,
                            label: `${this.getI18n('POS', 'FIELDS.commecial_responsible')} - ${this.getI18n('COMMON', 'name')}`,
                        },
                        commecial_responsible_email: {
                            required: false,
                            label: `${this.getI18n('POS', 'FIELDS.commecial_responsible')} - ${this.getI18n('COMMON', 'email')}`,
                        },
                        merchandising_responsib_name: {
                            required: false,
                            label: `${this.getI18n('POS', 'FIELDS.merchandising_responsible')} - ${this.getI18n('COMMON', 'name')}`,
                        },
                        merchandising_responsib_email: {
                            required: false,
                            label: `${this.getI18n('POS', 'FIELDS.merchandising_responsible')} - ${this.getI18n('COMMON', 'email')}`,
                        },
                    },
                    point_of_sale_channel: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                    },
                    point_of_sale_chain: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                    },
                    point_of_sale_regional: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                    },
                    point_of_sale_flag: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                    },
                    point_of_sale_category: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                    },
                    point_of_sale_revenue_has_business_unit: {
                        external_code: {
                            required: true,
                            label: this.getI18n(
                                'POS',
                                'IMPORT_FIELDS.external_code'
                            ),
                        },
                        business_unit_name: {
                            required: true,
                            label: this.getI18n(
                                'MIXES',
                                'IMPORT_FIELDS.business_unit_name'
                            ),
                        },
                        revenue: {
                            required: true,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.revenue'),
                        },
                        ranking: {
                            required: true,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.ranking'),
                        },
                        profile: {
                            required: true,
                            label: this.getI18n('POS', 'IMPORT_FIELDS.profile'),
                        },
                    },
                    brand: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                    },
                    business_unit: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                        contract_value: {
                            required: false,
                            label: this.getI18n('BUSINESS_UNITS', 'TITLES.contract_value')
                        },
                        contract_time: {
                            required: false,
                            label: this.getI18n('BUSINESS_UNITS', 'TITLES.contract_time')
                        },
                    },
                    point_of_sale_chains_has_flag: {
                        name: {
                            required: true,
                            label: this.getI18n('COMMON', 'name'),
                        },
                        flag_name: {
                            required: true,
                            label: this.getI18n('POS', 'TITLES.flag'),
                        },
                    },
                },
                selectTypes: [
                    {
                        value: 'users_email',
                        text: this.getI18n(
                            'QUESTIONNAIRE_FILTERS',
                            'TITLES.select_user_by_emails_csv'
                        ),
                    },
                    {
                        value: 'products_reference',
                        text: this.getI18n(
                            'QUESTIONNAIRE_FILTERS',
                            'TITLES.select_product_by_reference_csv'
                        ),
                    },
                    {
                        value: 'point_of_sales_external_code',
                        text: this.getI18n(
                            'QUESTIONNAIRE_FILTERS',
                            'TITLES.select_pos_by_external_code_csv'
                        ),
                    },
                ],
                selectFields: {
                    users_email: {
                        email: {
                            required: true,
                            label: this.getI18n('COMMON', 'email'),
                        },
                    },
                    products_reference: {
                        reference: {
                            required: true,
                            label: this.getI18n('PRODUCTS', 'FIELDS.reference'),
                        },
                    },
                    point_of_sales_external_code: {
                        external_code: {
                            required: true,
                            label: this.getI18n(
                                'POS',
                                'IMPORT_FIELDS.external_code'
                            ),
                        },
                    },
                },
            }
        },
        methods: {
            formatImportStatuses(value) {
                return this.getI18n('IMPORT_JOBS', `STATUSES.${value}`)
            },

            formatImportTypes(value) {
                return this.getI18n('IMPORT_JOBS', `TYPES.${value}`)
            },
        }
    }
</script>
